import styled from "styled-components";
import {
  type ThemeType,
  Button,
  TextField,
  Box,
  Text,
} from "@cruk/cruk-react-components";

const SEARCH_UI_DESKTOP_WIDTH = "25rem";

// to silence eslint rule that doesn't understand custom component input
export const Label = styled.label``;

export const LabelText = styled.span<{ theme: ThemeType }>`
  font-family: ${({ theme }) => theme.typography.fontFamilyHeadings};
  display: block;
  font-size: ${({ theme }) => theme.fontSizes.l};
  padding-bottom: ${({ theme }) => theme.spacing.xs};
`;

export const LabelTextHeading = styled.span<{ theme: ThemeType }>`
  font-family: ${({ theme }) => theme.typography.fontFamilyHeadings};
  display: block;
  font-size: ${({ theme }) => theme.fontSizes.xl};
  padding-bottom: ${({ theme }) => theme.spacing.xs};
`;

export const SearchButton = styled(Button)<{ theme: ThemeType }>`
  background-color: ${({ theme }) => theme.colors.backgroundLight};
  border-color: ${({ theme }) => theme.tokenColors.grey_900};
  border-width: 1px 1px 1px 0;
  border-style: solid;
  border-radius: 0;
`;

export const Flexrow = styled.div<{ theme: ThemeType }>`
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    flex-direction: row;
  }
`;

export const StyledForm = styled.form`
  width: 100%;
`;

export const SearchTextField = styled(TextField)`
  border-right-width: 0;
  /* fix for safari ios */
  margin-bottom: 0;
`;

export const SearchControlsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CustomTextResultsCounter = styled(Text)<{ theme: ThemeType }>`
  margin-left: ${({ theme }) => theme.spacing.s};
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    margin-left: 0;
  }
`;

export const SearchWrapper = styled.div<{ theme: ThemeType }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    max-width: ${SEARCH_UI_DESKTOP_WIDTH};
    margin: auto;
  }
`;

export const SearchSuggestionsWrapper = styled(Box)<{ theme: ThemeType }>`
  margin-left: ${({ theme }) => theme.spacing.s};
  margin-right: ${({ theme }) => theme.spacing.s};
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    max-width: ${SEARCH_UI_DESKTOP_WIDTH};
    margin: auto;
  }
`;

export const SearchResultsWrapper = styled(Box)<{ theme: ThemeType }>`
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    max-width: ${SEARCH_UI_DESKTOP_WIDTH};
    margin: auto;
  }
`;

export const SearchResultUl = styled.ul<{ theme: ThemeType }>`
  padding-left: ${({ theme }) => theme.spacing.xs};
  > li {
    list-style: disc;
  }
`;
export const SearchResultOl = styled.ol<{ theme: ThemeType }>`
  padding-left: ${({ theme }) => theme.spacing.xxs};
  list-style: none;
  margin-bottom: 0;
  & > li:nth-child(1) {
    display: flex;
  }
  & ul {
    margin-top: ${({ theme }) => theme.spacing.xxs};
    margin-bottom: 0;
  }
  & > li:nth-child(1)::before {
    content: "1)";
    margin-right: ${({ theme }) => theme.spacing.xxs};
    margin-bottom: ${({ theme }) => theme.spacing.xxs};
  }
  & > li:nth-child(2)::before {
    content: "2)";
    margin-right: ${({ theme }) => theme.spacing.xxs};
  }
`;
