import { FWS_BASE_URL } from "@fwa/src/services/apiClient";

const SEARCH_BASE_URL = process.env.NEXT_PUBLIC_SEARCH_BASE_URL || "";
const SEARCH_ENV =
  process.env.NEXT_PUBLIC_SEARCH_ENV &&
  process.env.NEXT_PUBLIC_SEARCH_ENV !== "~"
    ? process.env.NEXT_PUBLIC_SEARCH_ENV
    : ""; // We're unable to set an environment variable to be "" in AWS, so use "~" to indicate the same.

export const searchUrlHomepageSearch = () =>
  `${SEARCH_BASE_URL}/fundraisingteams${SEARCH_ENV},fundraisingpages${SEARCH_ENV}/_search`;

export const UrlSearchEventDefault = `${FWS_BASE_URL}/configs/events.defaults.json`;

export const createHomePageQueryRequestBody = (
  from: number,
  size: number,
  query: string,
) => ({
  size,
  from,
  query: {
    bool: {
      should: [
        {
          multi_match: {
            query,
            fields: [
              "url^5",
              "title^15",
              "description",
              "team_leaders.name^5",
              "team_members.name",
              "team_leaders.email^5",
              "owners.name^10",
              "owners.email^5",
              "in_memory.name^5",
              "events.title^2",
              "_type",
            ],
            type: "best_fields",
            operator: "or",
            fuzziness: 1,
          },
        },
        {
          multi_match: {
            query,
            fields: [
              "url^5",
              "title^15",
              "description",
              "team_leaders.name^5",
              "team_members.name",
              "team_leaders.email^5",
              "owners.name^10",
              "owners.email^5",
              "in_memory.name^5",
              "events.title^2",
              "_type",
            ],
            type: "phrase",
            operator: "or",
          },
        },
        {
          multi_match: {
            query,
            fields: [
              "url^5",
              "title^15",
              "description",
              "team_leaders.name^5",
              "team_members.name",
              "team_leaders.email^5",
              "owners.name^10",
              "owners.email^5",
              "in_memory.name^5",
              "events.title^2",
              "_type",
            ],
            type: "phrase_prefix",
            operator: "or",
          },
        },
      ],
      minimum_should_match: "1",
    },
  },
  sort: [{ _score: { order: "desc" } }, { created: { order: "desc" } }],
});

export const searchUrlTeamSearch = () =>
  `${SEARCH_BASE_URL}/fundraisingteams${SEARCH_ENV}/_search`;

export const createTeamSearchQuery = (
  from: number,
  size: number,
  searchString: string,
  event_code?: string,
) => ({
  from,
  size,
  query: {
    bool: {
      must: {
        multi_match: {
          query: searchString,
          fields: [
            "url^5",
            "title^10",
            "description",
            "body",
            "team_leaders.name^5",
            "team_members.name",
            "team_leaders.email^10",
            "events.title^2",
          ],
          type: "cross_fields",
          prefix_length: 1,
          operator: "and",
        },
      },
      ...(event_code && {
        should: [
          {
            match: {
              "events.event_code": {
                query: event_code,
                boost: 1.5,
              },
            },
          },
        ],
      }),
    },
  },
});

// Events do not have a local search env so setting __local to __int
export const searchUrlEventSearch = () =>
  `${SEARCH_BASE_URL}/events${
    SEARCH_ENV === "__local" ? "__int" : SEARCH_ENV
  },activities-tmp${SEARCH_ENV}/_search`;

export const createActivityEventSearchQuery = (
  searchString: string,
  eventSearchDefaults: string[],
) => {
  if (searchString) {
    return {
      from: 0,
      size: 6,
      query: {
        bool: {
          should: [
            {
              match_phrase: {
                title: searchString,
              },
            },
            {
              multi_match: {
                query: searchString,
                fields: ["title^3", "description", "body", "event_code"],
                type: "cross_fields",
                prefix_length: 1,
                operator: "and",
              },
            },
          ],
          minimum_should_match: 1,
          filter: [
            {
              range: {
                date_end: {
                  gte: "now-7d/d",
                },
              },
            },
            {
              exists: {
                field: "fundraising_pagetype.id",
              },
            },
            {
              term: {
                event_visibility: true,
              },
            },
          ],
        },
      },
    };
  }

  return {
    from: 0,
    size: 6,
    query: {
      bool: {
        should: eventSearchDefaults.map((v, i) => ({
          constant_score: {
            query: { ids: { values: [v] } },
            boost: eventSearchDefaults.length - i,
          },
        })),
        filter: [
          {
            range: {
              date_end: {
                gte: "now-7d/d",
              },
            },
          },
          {
            exists: {
              field: "fundraising_pagetype.id",
            },
          },
          {
            term: {
              event_visibility: true,
            },
          },
        ],
      },
    },
    sort: [
      "_score",
      {
        date_start: {
          order: "asc",
        },
      },
    ],
  };
};
