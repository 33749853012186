import jsSHA from "jssha";
import randomBytes from "randombytes";

import { isBrowser } from "@fwa/src/utils/browserUtils";
import { buildQuery } from "@fwa/src/utils/urlUtils";
import { trackDataDogError } from "@fwa/src/services/dataDog";
import { logger } from "@fwa/src/services/logger";

import { type OauthUserType, type TokenResponse } from "@fwa/src/types";

export const OAUTH_BASE_URL = process.env.NEXT_PUBLIC_OAUTH_BASE_URL || "";
export const OAUTH_CLIENT_ID = process.env.NEXT_PUBLIC_OAUTH_CLIENT_ID || "";

const REFRESH_THRESHOLD_SECONDS = 60 * 5; // 5min

// //////////////////////////     Utils   ////////////////////////////////////

// This is only really used in the log in link as
// we redirect users to the authentication web app to log in
const buildAuthURLPath = (
  redirectUrl: string,
  redirectState: string,
  followRedirect: boolean,
  challenge: string,
  path: string,
) =>
  `/${path}${buildQuery({
    client_id: OAUTH_CLIENT_ID,
    redirect_uri: redirectUrl,
    state: redirectState,
    response_type: "code",
    scope: "",
    code_challenge: challenge,
    code_challenge_method: "S256",
    noninteractive: followRedirect ? null : "true",
  })}`;

const base64URLEncode = (str: string | Buffer): string =>
  str
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore this is the browser api not node
    .toString("base64")
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");

const sha256 = (buffer: string) => {
  const shaObj = new jsSHA("SHA-256", "TEXT");
  shaObj.update(buffer);
  return shaObj.getHash("B64");
};

// //////////////////////////     Code challenge / verify    ////////////////////////////////////

export const getToken = (): string | null => {
  if (!isBrowser) {
    return null;
  }
  const token = localStorage.getItem("token");
  return token === "" ? null : token;
};

export const setToken = (token: string): void => {
  logger.log("set token");
  if (!isBrowser) {
    return undefined;
  }
  localStorage.setItem("token", token);
  return undefined;
};

export const getTokenExpiry = (): string | null => {
  if (!isBrowser) {
    return null;
  }
  const tokenexpiry = localStorage.getItem("tokenexpiry");
  return tokenexpiry === "" ? null : tokenexpiry;
};

export const setTokenExpiry = (ttl: number): void => {
  if (!isBrowser) {
    return undefined;
  }
  const refreshTimeMilliseconds = (ttl - REFRESH_THRESHOLD_SECONDS) * 1000;
  const expireTime = new Date().getTime() + refreshTimeMilliseconds;
  localStorage.setItem("tokenexpiry", expireTime.toString());
  return undefined;
};

export const hasActiveToken = (): boolean => {
  if (!getToken()) {
    return false;
  }
  const tokenExpireString = getTokenExpiry();
  if (!tokenExpireString) {
    return false;
  }
  const unixTime = new Date().getTime();
  if (unixTime < parseInt(tokenExpireString, 10)) {
    return true;
  }
  return false;
};

export const shouldRefresh = (): boolean => {
  if (!isBrowser) {
    return false;
  }
  const tokenExpireString = getTokenExpiry();
  if (!tokenExpireString) {
    logger.log("no tokenexpiry");
    return false;
  }
  const tokenExpireTime = parseInt(tokenExpireString, 10);
  const unixTime = new Date().getTime();

  return unixTime > tokenExpireTime;
};

export const clearToken = (): void => {
  logger.log("clear token");
  if (!isBrowser) {
    return undefined;
  }
  localStorage.setItem("token", "");
  localStorage.setItem("tokenexpiry", "");
  return undefined;
};

export const getVerifier = (): string | null => {
  if (!isBrowser) {
    return null;
  }
  return localStorage.getItem("verifier");
};

export const createVerifier = (): string => {
  if (!isBrowser) {
    return "";
  }
  const newVerifier: string = base64URLEncode(randomBytes(32));
  localStorage.setItem("verifier", newVerifier);
  return newVerifier;
};

export const clearVerifier = () => {
  logger.log("clear verifier");
  if (!isBrowser) {
    return undefined;
  }
  localStorage.setItem("verifier", "");
  return undefined;
};

export const challenge = (): string => {
  if (!isBrowser) {
    return "";
  }
  const existingVerifier = getVerifier();
  const verifier: string = existingVerifier || createVerifier();
  return base64URLEncode(sha256(verifier));
};

export const newChallenge = (): string => {
  if (!isBrowser) {
    return "";
  }
  return base64URLEncode(sha256(createVerifier()));
};

// Oauth API

// Generate the forgotten password URL (links to Oauth).
export const buildForgottenPasswordURL = (): string =>
  `${OAUTH_BASE_URL}/forgotten`;

/** for setting and  resetting password,
 * in the EM/AM one time log in journey a temp password will be provided and is saved on fundraiser context,
 * when the user has a Facebook login but no password null is acceptable for old password
 * */
export const resetPassword = (
  oldPassword: null | string,
  newPassword: string,
): Promise<Response> => {
  logger.info("resetting password");

  return fetch(`${OAUTH_BASE_URL}/api/users/me/password`, {
    method: "PATCH",
    body: JSON.stringify({
      oldPasswordUnencrypted: oldPassword,
      newPasswordUnencrypted: newPassword,
    }),
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${getToken() || ""}`,
      "Content-Type": "application/json",
    },
  });
};

export const disconnectFacebook = (): Promise<OauthUserType | void> => {
  logger.info("Removing Facebook/Social");

  return fetch(`${OAUTH_BASE_URL}/api/users/me`, {
    method: "PATCH",
    body: JSON.stringify({
      facebookId: null,
    }),
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${getToken() || ""}`,
      "Content-Type": "application/json",
    },
  })
    .then((res: Response) => {
      if (!res.ok) {
        throw new Error("Unable to remove Facebook/Social");
      }
      return res.json();
    })
    .then((res) => res as OauthUserType)
    .catch((err: Error) => {
      logger.error("Unable to remove Facebook/Social");
      trackDataDogError(err, { function: "disconnectFacebook" });
    });
};

// //////////////////////////     Authentication API calls   ////////////////////////////////////

interface AuthCodeResponse {
  authCode: string;
}

export const refreshToken = (): Promise<TokenResponse | void> => {
  logger.log("refreshToken");
  return fetch(
    `${OAUTH_BASE_URL}/authorize${buildQuery({
      client_id: OAUTH_CLIENT_ID,
      redirect_uri: logInRedirectUrl(),
      response_type: "code",
      scope: "",
      code_challenge: newChallenge(),
      code_challenge_method: "S256",
      noninteractive: "true",
    })}`,
    {
      redirect: "manual",
      credentials: "include",
    },
  )
    .then((res: Response) => {
      if (!res.ok) {
        throw new Error("Refresh token failed");
      }
      const json = res.json();
      return json;
    })
    .then((json: AuthCodeResponse) => getTokenFromAuthCode(json.authCode))
    .catch(() => {
      logger.error("Unable to refresh token");
      // clear local state with previous expired auth info
      clearVerifier();
      clearToken();
    });
};

export const getTokenFromAuthCode = (authCode: string) => {
  logger.info("get token from auth code");
  // we want to return a promise and keep typescript happy
  // so he have to stub a few things out with empty strings to be SSR safe
  const verifier = isBrowser ? getVerifier() || "" : "";

  return fetch(`${OAUTH_BASE_URL}/token`, {
    method: "POST",
    body: JSON.stringify({
      grant_type: "authorization_code",
      client_id: OAUTH_CLIENT_ID,
      code_verifier: verifier,
      code: authCode,
      redirect_uri: logInRedirectUrl(),
      code_challenge_method: "S256",
    }),
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${getToken() || ""}`,
      "Content-Type": "application/json",
    },
  })
    .then((res: Response) => {
      if (!res.ok) {
        throw new Error("get token from auth code failed");
      }
      const json = res.json();
      return json;
    })
    .then((res: TokenResponse) => {
      setToken(res.access_token);
      setTokenExpiry(res.expires_in);
      return res;
    })
    .catch(async (err: Error) => {
      trackDataDogError(err, { function: "getTokenFromAuthCode" });
      logger.error("Unable get token from auth code");
      await logOut();
    });
};

const logInRedirectUrl = (): string => {
  if (!isBrowser) {
    return "";
  }
  const baseUrl = window.origin;
  const returnUrl = `${baseUrl}/auth`;
  return returnUrl;
};

type ExtraParamsType = Record<string, string>;

const logInRedirectState = (extraParams?: ExtraParamsType): string => {
  if (!isBrowser) {
    return "";
  }
  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.delete("code");
  if (extraParams) {
    Object.keys(extraParams).forEach((key) => {
      urlSearchParams.append(key, extraParams[key]);
    });
  }
  let queryString = urlSearchParams.toString();
  queryString = queryString ? `?${queryString}` : "";
  const pathWithQuery = window.location.pathname + queryString;
  // if user logs in from homepage redirect to dashboard
  const redirectUrl =
    pathWithQuery === "/" ? `/fundraiser/dashboard` : pathWithQuery;
  const redirectUrlNoHash = redirectUrl.replace(/#.*/, "");
  // This is completely arbitrary at 800, with worth noting that
  // the return url as a whole gets truncated to 1024 when redirected by from legacy oauth
  // When this happens the auth /auth page redirects the user to their dashboard anyway
  const isTooLong = redirectUrlNoHash.length >= 1024;
  const redirectUrlCorrectLength = isTooLong
    ? `/fundraiser/dashboard`
    : redirectUrlNoHash;
  return redirectUrlCorrectLength;
};

export const logIn = () => {
  if (isBrowser) {
    // Redirect to Oauth.
    const loginUrl = `${OAUTH_BASE_URL}${buildAuthURLPath(
      logInRedirectUrl(),
      logInRedirectState(),
      true,
      challenge(),
      "authorize",
    )}`;
    window.location.href = loginUrl;
  }
};

export const createUser = () => {
  if (isBrowser) {
    // Redirect to Oauth.
    const loginUrl = `${OAUTH_BASE_URL}${buildAuthURLPath(
      logInRedirectUrl(),
      logInRedirectState(),
      true,
      challenge(),
      "create",
    )}`;
    window.location.href = loginUrl;
  }
};

export const connectFacebook = () => {
  if (isBrowser) {
    // Redirect to Oauth.

    window.location.href = `${OAUTH_BASE_URL}/connect/facebook${buildQuery({
      destination: buildAuthURLPath(
        logInRedirectUrl(),
        logInRedirectState({ setAvatar: "true" }),
        true,
        challenge(),
        "authorize",
      ),
    })}`;
  }
};

export const logOut = async () => {
  logger.log("log out");

  if (!isBrowser) return undefined;

  await fetch(`${OAUTH_BASE_URL}/logout`, {
    redirect: "follow",
    credentials: "include",
  })
    .then((res: Response) => {
      if (!res.ok) {
        const newError = Error("Log out failed");
        trackDataDogError(newError, {
          function: "logOut",
          status: res.status,
        });
        throw newError;
      }
      return undefined;
    })
    .catch((err: Error) => {
      logger.error("Unable to logout");
      trackDataDogError(err, { function: "logOut" });
    });

  clearVerifier();
  clearToken();
  return undefined;
};
