import styled from "styled-components";
import {
  type ThemeType,
  Button,
  type ColorKeyType,
  Text,
} from "@cruk/cruk-react-components";

const MAX_BUBBLE_WIDTH = "25rem";
const MAX_BUBBLE_WIDTH_MOBILE = "18rem";
const FEATURE_WRAPPER_MARGIN = "5.5rem";
const FEATURE_WRAPPER_MARGIN_DESKTOP = "6.5rem";
const HERO_WRAPPER_PADDING = "6rem";
const IMAGE_WIDTH = "17rem";
const HERO_IMAGE_WIDTH = "36rem";
const HERO_BODY_WIDTH_DESKTOP = "25rem";
const HERO_BODY_WIDTH = "22rem";
const NUMBER_BUBBLE_WIDTH = "6em";

export const HeroWrapper = styled.div<{ theme: ThemeType }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 28rem;
  max-width: 100vw;
  overflow: hidden;
  padding: 0 0 ${HERO_WRAPPER_PADDING} 0;
  margin-top: -${({ theme }) => theme.spacing.s};

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    flex-direction: row;
    justify-content: center;
    padding: ${({ theme }) => theme.spacing.m} 0
      ${({ theme }) => theme.spacing.xl} 0;
    margin-top: 0;
  }
`;

export const HeroContentWrapper = styled.div<{ theme: ThemeType }>`
  margin-left: 0;
  margin-top: -16rem;
  & > * {
    padding: ${({ theme }) => theme.spacing.xxl};
  }
  max-width: ${HERO_BODY_WIDTH};
  align-self: flex-start;
  @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
    align-self: center;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    margin-left: -9rem;
    margin-top: 0;
    max-width: ${HERO_BODY_WIDTH_DESKTOP};
    flex: 1 0 ${HERO_BODY_WIDTH_DESKTOP};
    & > * {
      padding: ${({ theme }) => theme.spacing.xl};
    }
    align-self: center;
  }
`;

export const HeroBtnWrapper = styled.div`
  margin: auto;
  text-align: center;
`;

export const HeroImage = styled.img<{ theme: ThemeType }>`
  border-radius: 50%;
  width: ${HERO_IMAGE_WIDTH};
  height: ${HERO_IMAGE_WIDTH};
  margin-top: -4em;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    margin-top: 0;
  }
`;

export const Bubble = styled.div<{
  theme: ThemeType;
  $backgroundColor?: string;
  $variation: number;
  $inView: boolean;
}>`
  z-index: 1;
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform-origin: center;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ theme: { colors }, $backgroundColor }) =>
    $backgroundColor &&
    typeof colors[$backgroundColor as ColorKeyType] !== "undefined"
      ? colors[$backgroundColor as ColorKeyType]
      : $backgroundColor || colors.backgroundMid};
  opacity: 1;
`;

const bubbleVariations = [
  {
    bubble: `rotate(155deg) scale(4) translateY(-355%)`,
    bubbleMobile: `rotate(155deg) scale(4) translateY(-335%)`,
  },
  {
    bubble: `rotate(140deg) scale(1.5) translateY(-900%)`,
    bubbleMobile: `rotate(140deg) scale(1.5) translateY(-900%)`,
  },
];

export const HeroBubble = styled(Bubble)<{
  theme: ThemeType;
  $variation: number;
}>`
  transform: ${({ $variation }) => bubbleVariations[$variation].bubbleMobile};
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    transform: ${({ $variation }) => bubbleVariations[$variation].bubble};
  }
`;

export const JumpToButton = styled(Button)<{ theme: ThemeType }>`
  && {
    width: 4rem;
    height: 4rem;
    background-color: ${({ theme }) => theme.tokenColors.magenta_500};
    border-radius: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const CircleWrapperFixedWidth = styled.div<{ theme: ThemeType }>`
  max-width: ${MAX_BUBBLE_WIDTH_MOBILE};
  height: ${MAX_BUBBLE_WIDTH_MOBILE};
  margin: 0 auto;
  padding: 1rem;
  background-color: ${({ theme }) => theme.tokenColors.navy_100};
  border-radius: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    max-width: ${MAX_BUBBLE_WIDTH};
    height: ${MAX_BUBBLE_WIDTH};
  }
`;

export const GivingPageDemoImageWrapper = styled.div<{ theme: ThemeType }>`
  width: 50%;
  text-align: center;
  margin: ${({ theme }) => theme.spacing.l} auto 0 auto;
  transform: rotate(4deg);
  & img {
    box-shadow: 0px 3px 6px 0px ${({ theme }) => theme.tokenColors.grey_100};
    border-radius: 5px;
    height: ${IMAGE_WIDTH};
    width: auto;
    margin: auto;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    margin: 0 ${({ theme }) => theme.spacing.xl};
  }
`;

export const SectionWrapper = styled.div<{ theme: ThemeType }>`
  max-width: ${({ theme }) => theme.breakpoint.tablet};
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacing.s};
  margin: 0 auto;

  & > * {
    margin: ${FEATURE_WRAPPER_MARGIN} 0;
    @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
      margin: ${FEATURE_WRAPPER_MARGIN_DESKTOP} auto;
    }
  }

  & > :last-child {
    margin: ${FEATURE_WRAPPER_MARGIN} 0 0 0;
  }
`;

export const ConnectAppImagesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 1fr 0.5fr 1fr;
  grid-column-gap: 0px;
  max-width: ${IMAGE_WIDTH};
  max-height: ${IMAGE_WIDTH};
  margin: auto;
`;

export const FacebookLogoWrapper = styled.div`
  grid-area: 1 / 3 / 2 / 6;
  & img {
    border-radius: 100%;
  }
`;
export const StravaLogoWrapper = styled.div`
  grid-area: 3 / 1 / 3 / 4;
  & img {
    border-radius: 100%;
  }
`;
export const FitBitLogoWrapper = styled.div`
  grid-area: 3 / 5 / 3 / 8;
  & img {
    border-radius: 100%;
  }
`;

export const ImpactJumpToButtonCopyWrapper = styled.div<{ theme: ThemeType }>`
  margin-left: ${({ theme }) => theme.spacing.xs};
  margin-right: ${({ theme }) => theme.spacing.xs};

  @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
    margin-left: ${({ theme }) => theme.spacing.s};
    margin-right: ${({ theme }) => theme.spacing.s};
  }
`;
export const ImpactStatementWrapper = styled.div<{ theme: ThemeType }>`
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    display: flex;
    flex-direction: row-reverse;
  }
`;

export const ImpactJumpToTitle = styled(Text)<{ theme: ThemeType }>`
  font-family: ${({ theme }) => theme.typography.fontFamilyHeadings};
`;

export const ImpactStatementCopyWrapper = styled.div<{ theme: ThemeType }>`
  display: flex;
  flex-direction: column;
  padding-left: ${({ theme }) =>
    `calc(${theme.spacing.xs} + ${NUMBER_BUBBLE_WIDTH})`};
`;

export const BubbleText = styled.div<{ theme: ThemeType }>`
  margin: ${({ theme }) => `${theme.spacing.l} auto ${theme.spacing.xl}`};
`;

export const TopBubbleMobileSpacer = styled.div<{ theme: ThemeType }>`
  margin: ${({ theme }) => `${theme.spacing.l} auto ${theme.spacing.xxl}`};
  padding-bottom: ${({ theme }) => theme.spacing.xl};
`;
