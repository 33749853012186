import styled from "styled-components";
import { Box, type ThemeType } from "@cruk/cruk-react-components";

export const SearchResultsResponsiveRow = styled.div<{
  theme: ThemeType;
}>`
  display: flex;
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadows.m};
  padding: ${({ theme }) => theme.spacing.xs};
  background-color: ${({ theme }) => theme.colors.backgroundLight};
`;

export const SearchResultCardWrapper = styled.div<{ theme: ThemeType }>`
  padding: ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.s} 0
    ${({ theme }) => theme.spacing.s};
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    padding: 0 0 0 0;
    margin-bottom: ${({ theme }) => theme.spacing.s};
  }
`;

export const IconWrapperCirclePageResults = styled.div<{
  theme: ThemeType;
}>`
  display: block;
  width: 60px;
  height: 60px;
  background-color: ${({ theme }) => theme.colors.backgroundLight};
  border-radius: 50%;
  border: solid 2px ${({ theme }) => theme.colors.primary};
  transition: border 0.3s ease;
  &:hover {
    border-width: 6px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    width: 90px;
    height: 90px;
  }
`;

export const ItemLink = styled.span<{
  theme: ThemeType;
}>`
  flex: 1 0;
  display: block;
  text-decoration: none;
  white-space: normal;
  word-break: break-word;
  h2 {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
  }
  &:hover h2 {
    color: ${({ theme }) => theme.colors.linkColorHover};
    text-decoration: underline;
  }
`;

export const TextContent = styled(Box)<{
  theme: ThemeType;
}>`
  overflow: hidden;

  p {
    margin-bottom: none !important;
  }
  &:not(:last-child) {
    p {
      margin-bottom: ${({ theme }) => theme.spacing.xs} !important;
    }
  }
`;
