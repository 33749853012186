import NextLink from "next/link";
import { Box } from "@cruk/cruk-react-components";
import { useTrackingContext } from "@fwa/src/contexts/TrackingContext";
import {
  TextMultiLineEllipsis,
  SingleLineElipsis,
  HeadingMultiLineEllipsis,
} from "@fwa/src/components/styles";
import {
  SearchResultsResponsiveRow,
  ItemLink,
  TextContent,
  SearchResultCardWrapper,
  IconWrapperCirclePageResults,
} from "@fwa/src/components/SearchResultHomePage/styles";

import {
  type SearchResultType,
  type SearchResultPageType,
  type SearchResultTeamType,
  type SearchTeamMemberType,
  type SearchOwnerType,
} from "@fwa/src/types";

const memberPageCrukSvg = "/assets/images/img/member-page-cruk-128.svg";
const memberTeamCrukSvg = "/assets/images/img/member-team-cruk-128.svg";
const inMemoryIconCruk = "/assets/images/img/in-memory-cruk-128.svg";

type Props = {
  result: SearchResultType;
  searchQuery?: string;
};

type SearchItemDataType = {
  membersString: string;
  icon: string;
  inMemoryString: null | string;
};

export const SearchResultHomePage = ({
  result: { _source: source, _index },
  searchQuery,
}: Props) => {
  const { trackError } = useTrackingContext();
  // Search results can be polymorphic, fundraiser, in memory, team
  // this function checks what it is and outputs some page data accordingly
  const searchResultItemData = (): SearchItemDataType => {
    try {
      if (_index.startsWith("fundraisingteams")) {
        const teamSource = source as SearchResultTeamType;
        // get team leader name there can only be one
        const teamLeader: SearchTeamMemberType | null = teamSource?.team_leaders
          ?.length
          ? teamSource.team_leaders[0]
          : null;

        const teamMembersSource = teamSource?.team_members || [];
        // get team member names, filter out the leader
        const teamMembers: SearchTeamMemberType[] = [...teamMembersSource];
        const teamMemberNamesNoLeader: string[] = teamMembers
          .filter((member: SearchTeamMemberType) => member !== teamLeader)
          .map((member: SearchTeamMemberType) => member.name)
          .filter(
            (member: string, index: number, array: string[]) =>
              array.indexOf(member) === index, // deduplicate
          );
        // TODO shouldn't the server being doing this not the front end
        const fullNamesArray = [
          ...(teamLeader ? [teamLeader.name] : []),
          ...teamMemberNamesNoLeader,
        ];

        return (
          // team
          {
            membersString: `${
              fullNamesArray.length > 4
                ? `${fullNamesArray.length} members including: ${fullNamesArray
                    .filter((item, index: number) => index < 5)
                    .join(", ")}`
                : `Members: ${fullNamesArray.join(", ")}`
            }`,
            icon: memberTeamCrukSvg,
            inMemoryString: null,
          }
        );
      }
      if (_index.startsWith("fundraisingpages")) {
        const pageSource = source as SearchResultPageType;
        return {
          membersString: pageSource.owners
            .map((member: SearchOwnerType) => member.name)
            .join(", "),
          icon: pageSource.in_memory.name
            ? inMemoryIconCruk
            : memberPageCrukSvg,
          inMemoryString: pageSource.in_memory.name
            ? `In memory of: ${pageSource.in_memory.name}`
            : null,
        };
      }
      // empty
      return {
        membersString: "",
        icon: "",
        inMemoryString: null,
      };
    } catch (e) {
      trackError(e as Error, {
        component: "searchResultItemData",
        searchQuery,
      });
      return {
        membersString: "",
        icon: "",
        inMemoryString: null,
      };
    }
  };

  const pageOrTeamSource = source as
    | SearchResultPageType
    | SearchResultTeamType;
  const eventString: string | null = pageOrTeamSource?.events?.length
    ? `Events: ${pageOrTeamSource.events
        .map((event) => event.title)
        .join(", ")}`
    : null;

  return (
    <NextLink
      href={pageOrTeamSource.path}
      aria-label={`${pageOrTeamSource.title}`}
      data-cta-type="search-item"
    >
      <ItemLink>
        <SearchResultCardWrapper>
          <SearchResultsResponsiveRow>
            <Box marginBottom="none" paddingRight="s">
              <IconWrapperCirclePageResults>
                <img src={searchResultItemData().icon} alt="" />
              </IconWrapperCirclePageResults>
            </Box>
            <TextContent>
              <HeadingMultiLineEllipsis
                h2
                textSize="l"
                textColor="primary"
                marginBottom="xs"
                $lineCount={2}
              >
                {pageOrTeamSource.title}
              </HeadingMultiLineEllipsis>

              {searchResultItemData().membersString && (
                <TextMultiLineEllipsis $lineCount={3}>
                  {searchResultItemData().membersString}
                </TextMultiLineEllipsis>
              )}

              {searchResultItemData().inMemoryString && (
                <SingleLineElipsis>
                  {searchResultItemData().inMemoryString}
                </SingleLineElipsis>
              )}

              {eventString && (
                <SingleLineElipsis>{eventString}</SingleLineElipsis>
              )}

              {pageOrTeamSource.description && (
                <SingleLineElipsis>
                  {pageOrTeamSource.description}
                </SingleLineElipsis>
              )}
            </TextContent>
          </SearchResultsResponsiveRow>
        </SearchResultCardWrapper>
      </ItemLink>
    </NextLink>
  );
};

export default SearchResultHomePage;
