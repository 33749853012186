import { isBrowser } from "@fwa/src/utils/browserUtils";

/* eslint-disable  @typescript-eslint/ban-ts-comment */
export const sendGTMTrackingEvent = (data: object) => {
  if (!isBrowser) return;
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  window.dataLayer.push(data);
};
/* eslint-enable */

export default sendGTMTrackingEvent;
