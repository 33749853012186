import React, { type ReactNode } from "react";
import { useInView } from "react-intersection-observer";

import {
  OuterWrapper,
  BubbleWrapper,
  ChildrenWrapper,
  ImageStyled,
  MainContent,
  Bubble1,
  Bubble2,
  Bubble3,
  ImageRoundedWrapper,
} from "./styles";

export const BubbleCard = ({
  children,
  imageChild,
  imageUrl,
  alt,
  imageRight = true,
  variation = 3,
  thirdBubbleColor = "tertiary",
  isAnimated = true,
}: {
  imageUrl?: string;
  imageChild?: ReactNode;
  alt?: string;
  children?: ReactNode;
  imageRight?: boolean;
  variation?: number;
  thirdBubbleColor?: string;
  isAnimated?: boolean;
}) => {
  const { ref: bubbleRef, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <OuterWrapper $imageRight={imageRight} ref={bubbleRef}>
      <BubbleWrapper $inView={inView} $isAnimated={isAnimated}>
        <Bubble1
          $variation={variation}
          $inView={inView}
          $backgroundColor="primary"
          $isAnimated={isAnimated}
        />
        <Bubble2
          $variation={variation}
          $inView={inView}
          $backgroundColor="secondary"
          $isAnimated={isAnimated}
        />
        <Bubble3
          $variation={variation}
          $inView={inView}
          $backgroundColor={thirdBubbleColor}
          $isAnimated={isAnimated}
        />
        {imageUrl ? (
          <ImageRoundedWrapper>
            <ImageStyled src={imageUrl} alt={alt} />
          </ImageRoundedWrapper>
        ) : null}

        {!imageUrl && imageChild ? (
          <ChildrenWrapper>{imageChild}</ChildrenWrapper>
        ) : null}
      </BubbleWrapper>
      <MainContent $imageRight={imageRight}>{children}</MainContent>
    </OuterWrapper>
  );
};

export default BubbleCard;
