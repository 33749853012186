import styled, { keyframes } from "styled-components";
import { type ColorKeyType, type ThemeType } from "@cruk/cruk-react-components";

const IMAGE_WIDTH = "272px";

const rotate = keyframes`
  from {
    transform: rotate(500deg) scale(0) translateY(100%);
  }

`;

const grow = keyframes`
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

export const Bubble = styled.div<{
  $backgroundColor?: string;
  $variation: number;
  $inView: boolean;
  $isAnimated: boolean;
  theme: ThemeType;
}>`
  z-index: -1;
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform-origin: center;
  transform: translate(-50%, -50%) scale(1);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ theme: { colors }, $backgroundColor }) =>
    $backgroundColor &&
    typeof colors[$backgroundColor as ColorKeyType] !== "undefined"
      ? colors[$backgroundColor as ColorKeyType]
      : $backgroundColor || colors.backgroundMid};
  animation-name: ${({ $inView }) => ($inView ? rotate : "none")};
  animation-duration: ${({ $isAnimated }) => ($isAnimated ? "1.5s" : "0")};
`;

const bubbleVariations = [
  {
    bubble1: `rotate(50deg) scale(6) translateY(-200%)`,
    bubble2: `rotate(220deg) scale(3) translateY(-400%)`,
    bubble3: `rotate(240deg) scale(1) translateY(-1100%)`,
  },
  {
    bubble1: `rotate(130deg) scale(5) translateY(-180%)`,
    bubble2: `rotate(320deg) scale(3) translateY(-420%)`,
    bubble3: `rotate(300deg) scale(1) translateY(-1200%)`,
  },
  {
    bubble1: `rotate(220deg) scale(2) translateY(-590%)`,
    bubble2: `rotate(30deg) scale(4) translateY(-260%)`,
    bubble3: `rotate(240deg) scale(1) translateY(0%)`,
  },
  {
    bubble1: `rotate(310deg) scale(2) translateY(-600%)`,
    bubble2: `rotate(330deg) scale(3) translateY(-360%)`,
    bubble3: `rotate(130deg) scale(4) translateY(-240%)`,
  },
  {
    bubble1: `rotate(30deg) scale(4) translateY(-300%)`,
    bubble2: `rotate(218deg) scale(2.5) translateY(-440%)`,
    bubble3: `rotate(230deg) scale(1) translateY(-1175%)`,
  },
  {
    bubble1: `rotate(295deg) scale(3) translateY(-250%)`,
    bubble2: `rotate(180deg) scale(2) translateY(-375%)`,
    bubble3: `rotate(65deg) scale(2) translateY(-320%)`,
  },
  {
    bubble1: `rotate(225deg) scale(3) translateY(300%)`,
    bubble2: `rotate(225deg) scale(5) translateY(-185%)`,
    bubble3: `rotate(210deg) scale(1) translateY(1000%)`,
  },
  {
    bubble1: `rotate(306deg) scale(5) translateY(175%)`,
    bubble2: `rotate(325deg) scale(3) translateY(-365%)`,
    bubble3: `rotate(130deg) scale(1) translateY(1150%)`,
  },
  {
    bubble1: `rotate(49deg) scale(1.5) translateY(700%)`,
    bubble2: `rotate(50deg) scale(3) translateY(-275%)`,
    bubble3: `rotate(130deg) scale(1) translateY(0%)`,
  },
  {
    bubble1: `rotate(125deg) scale(1.5) translateY(700%)`,
    bubble2: `rotate(320deg) scale(3) translateY(-300%)`,
    bubble3: `rotate(310deg) scale(3) translateY(330%)`,
  },
  {
    bubble1: `rotate(35deg) scale(3) translateY(-300%)`,
    bubble2: `rotate(220deg) scale(4) translateY(-200%)`,
    bubble3: `rotate(200deg) scale(1) translateY(1030%)`,
  },
  {
    bubble1: `rotate(130deg) scale(3) translateY(-350%)`,
    bubble2: `rotate(320deg) scale(3) translateY(-300%)`,
    bubble3: `rotate(125deg) scale(1.5) translateY(710%)`,
  },
];

export const Bubble1 = styled(Bubble)<{
  $variation: number;
  theme: ThemeType;
}>`
  transform: ${({ $variation }) => bubbleVariations[$variation].bubble1};
`;
export const Bubble2 = styled(Bubble)<{
  $variation: number;
  theme: ThemeType;
}>`
  transform: ${({ $variation }: { $variation: number; theme: ThemeType }) =>
    bubbleVariations[$variation].bubble2};
`;
export const Bubble3 = styled(Bubble)<{
  $variation: number;
  theme: ThemeType;
}>`
  transform: ${({ $variation }: { $variation: number; theme: ThemeType }) =>
    bubbleVariations[$variation].bubble3};
`;
export const OuterWrapper = styled.div<{
  $imageRight: boolean;
  theme: ThemeType;
}>`
  display: block;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    display: flex;
    flex-direction: ${({ $imageRight }) =>
      $imageRight ? "row-reverse" : "row"};
  }
`;

export const BubbleWrapper = styled.div<{
  $inView: boolean;
  $isAnimated: boolean;
  theme: ThemeType;
}>`
  z-index: 0;
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  transform-origin: center;
  animation-name: ${({ $inView }) => ($inView ? grow : "none")};
  animation-duration: ${({ $isAnimated }) => ($isAnimated ? "1s" : "0")};

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    margin-bottom: 0;
  }
`;

export const ImageRoundedWrapper = styled.div<{ theme: ThemeType }>`
  display: block;
  overflow: hidden;
  max-height: ${IMAGE_WIDTH};
  max-width: ${IMAGE_WIDTH};
  border-radius: 50%;

  margin: ${({ theme }) => `0 auto ${theme.spacing.l} auto`};

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    margin: 0;
  }
`;

export const ChildrenWrapper = styled.div<{ theme: ThemeType }>`
  display: block;
  overflow: hidden;
  margin: ${({ theme }) => `0 auto ${theme.spacing.l} auto`};
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    margin: 0;
  }
`;

export const ImageStyled = styled.img`
  height: ${IMAGE_WIDTH};
  width: ${IMAGE_WIDTH};
  object-fit: cover;
`;

export const MainContent = styled.div<{
  $imageRight: boolean;
  theme: ThemeType;
}>`
  flex: 1 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    padding-right: ${({ $imageRight, theme }) =>
      $imageRight ? theme.spacing.m : 0};
    padding-left: ${({ $imageRight, theme }) =>
      $imageRight ? 0 : theme.spacing.m};
  }
`;
