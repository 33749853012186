import Head from "next/head";

import { HomePage } from "@fwa/src/components/HomePage";

const Home = () => (
  <>
    <Head>
      <title>Charity Fundraising | Cancer Research UK Giving Pages</title>
      <meta
        name="description"
        content="Creating your Giving Page helps your fundraising go further. Whether it's running a marathon or a bake sale help to fundraise for Cancer Research UK today."
      />
    </Head>
    <HomePage />
  </>
);

export default Home;
