import { type ThemeType } from "@cruk/cruk-react-components";
import styled from "styled-components";

const NUMBER_BUBBLE_WIDTH = "6em";

export const NumberBubbleWrapper = styled.div<{
  $isVisible: boolean;
  $positionShift: number;
  theme: ThemeType;
}>`
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  transition:
    opacity 1s ease,
    top 0.5s linear;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};

  top: ${({ $positionShift }) =>
    `calc((50% - (3  * ${NUMBER_BUBBLE_WIDTH} / 2)) -  (${$positionShift} *  ${NUMBER_BUBBLE_WIDTH}))`};
  left: ${({ theme }) => theme.spacing.s};

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    left: ${({ theme }) =>
      `calc(((100% - ${theme.breakpoint.tablet}) / 2) + ${theme.spacing.s})`};
  }
`;

export const NumberBubble = styled.div<{
  $inFocus?: boolean;
  $isVisible?: boolean;
  theme: ThemeType;
}>`
  border-radius: 50%;
  width: ${NUMBER_BUBBLE_WIDTH};
  height: ${NUMBER_BUBBLE_WIDTH};
  color: ${({ theme }) => theme.tokenColors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  transition:
    transform 0.75s cubic-bezier(0.77, -2, 0.25, 2),
    opacity 0.5s linear,
    background-color 0.5s linear,
    color 0.5s linear;
  transform: ${({ $inFocus }) => ($inFocus ? "scale(1)" : "scale(0.65)")};
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  color: ${({ theme, $inFocus }) =>
    $inFocus ? theme.colors.textOnPrimary : theme.colors.primary};
  background-color: ${({ theme, $inFocus }) =>
    $inFocus ? theme.colors.primary : theme.colors.backgroundLightColor};
  z-index: 1;
  box-shadow: 0px 0px 10px 0px rgba(275, 255, 255, 1);
`;
