import {
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  useEffect,
} from "react";
import { useInView } from "react-intersection-observer";

type Props = {
  number: number;
  children?: ReactNode;
  visibleImpactStatements: number[];
  setVisibleImpactStatements: Dispatch<SetStateAction<number[]>>;
};

export const HomePageImpactStatement = ({
  number,
  children,
  visibleImpactStatements,
  setVisibleImpactStatements,
}: Props) => {
  const { ref: statementRef, inView } = useInView({
    threshold: 0.01,
    rootMargin: "-40% 0px",
  });

  useEffect(() => {
    const inList = visibleImpactStatements.find((item) => item === number);
    if (inView && !inList) {
      setVisibleImpactStatements([number]);
    }
    if (!inView && inList) {
      setVisibleImpactStatements(
        visibleImpactStatements.filter((item) => item !== number),
      );
    }
  }, [inView]);

  return (
    <div ref={statementRef} style={{ minHeight: "4px" }}>
      {children}
    </div>
  );
};

export default HomePageImpactStatement;
