/* eslint-disable @typescript-eslint/no-explicit-any */
const ENV_NAME: string = process.env.NEXT_PUBLIC_ENV_NAME || "";

export const logger = {
  error: (message?: any, ...optionalParams: any[]) => {
    if (ENV_NAME !== "production") {
      console.error(message, ...optionalParams);
    }
  },
  log: (message?: any, ...optionalParams: any[]) => {
    if (ENV_NAME !== "production") {
      console.log(message, ...optionalParams);
    }
  },
  info: (message?: any, ...optionalParams: any[]) => {
    if (ENV_NAME !== "production") {
      console.info(message, ...optionalParams);
    }
  },
};

export default logger;
