import {
  NumberBubbleWrapper,
  NumberBubble,
} from "@fwa/src/components/HomePageImpactMoney/styles";

type Props = {
  visibleImpactStatements: number[];
};

export const HomePageImpactMoney = ({ visibleImpactStatements }: Props) => {
  const lastPair =
    visibleImpactStatements.length <= 2 && visibleImpactStatements.includes(7);
  const isVisible = visibleImpactStatements.length > 0 && !lastPair;
  const visibleImpactStatementsSorted = visibleImpactStatements.sort();

  // figure out what item to show
  const middleIndex = Math.ceil(visibleImpactStatements.length / 2) - 1;

  const selectedNumber = visibleImpactStatementsSorted[middleIndex];
  const positionShift = selectedNumber > 2 ? selectedNumber - 1 : 0;

  // slide up and down for selected value but not for first 2 and last value
  const positionShiftCentralised = Math.min(
    Math.max(positionShift - 1, 0),
    3,
    3,
  );

  return (
    <NumberBubbleWrapper
      $isVisible={isVisible}
      $positionShift={positionShiftCentralised}
    >
      <NumberBubble
        $inFocus={selectedNumber === 1}
        $isVisible={[1, 2].includes(selectedNumber)}
      >
        £100
      </NumberBubble>
      <NumberBubble
        $inFocus={selectedNumber === 2}
        $isVisible={[1, 2, 3].includes(selectedNumber)}
      >
        £250
      </NumberBubble>
      <NumberBubble
        $inFocus={selectedNumber === 3}
        $isVisible={[1, 2, 3, 4].includes(selectedNumber)}
      >
        £500
      </NumberBubble>
      <NumberBubble
        $inFocus={selectedNumber === 4}
        $isVisible={[3, 4, 5, 6].includes(selectedNumber)}
      >
        £1,000
      </NumberBubble>
      <NumberBubble
        $inFocus={selectedNumber === 5}
        $isVisible={[4, 5, 6].includes(selectedNumber)}
      >
        £2,500
      </NumberBubble>
      <NumberBubble
        $inFocus={selectedNumber === 6}
        $isVisible={[5, 6].includes(selectedNumber)}
      >
        £5,000
      </NumberBubble>
    </NumberBubbleWrapper>
  );
};

export default HomePageImpactMoney;
