import { type ThemeType, type ColorKeyType } from "@cruk/cruk-react-components";
import React, { type ReactNode } from "react";
import styled from "styled-components";

const circleSVGString = ({
  backgroundColor = "#ededed",
}: {
  backgroundColor?: string;
}) =>
  Buffer.from(
    `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 100 100"><circle class="a" cx="50" cy="50" r="50" fill="${backgroundColor}" /></svg>`,
    "binary",
  ).toString("base64");

const CircleWrapperStyled = styled.div<{
  $backgroundColor?: string;
  $hasOverflow: boolean;
  $maxWidth: string;
  theme: ThemeType;
}>`
  position: relative;
  max-width: ${({ $maxWidth }) => $maxWidth};
  max-height: ${({ $maxWidth }) => $maxWidth};
  margin: auto;
  padding: 5rem 0;
  background-image: ${({ theme: { colors }, $backgroundColor }) => {
    const calcColour =
      $backgroundColor &&
      typeof colors[$backgroundColor as ColorKeyType] !== "undefined"
        ? colors[$backgroundColor as ColorKeyType]
        : $backgroundColor || colors.backgroundMid;
    return `url("data:image/svg+xml;base64,${circleSVGString({
      backgroundColor: calcColour,
    })}")`;
  }};
  background-size: ${({ $hasOverflow }) =>
    $hasOverflow ? "cover" : "inherit"};
  background-position-y: center;
  background-position-x: center;
  background-repeat: no-repeat;
`;

const CircleContentWrapper = styled.div<{ theme: ThemeType }>`
  position: relative;
  margin: ${({ theme }) => theme.spacing.m} auto;
`;

type CircleWrapperProps = {
  children?: ReactNode;
  backgroundColor?: string;
  hasOverflow?: boolean;
  maxWidth?: string;
};

export const CircleWrapper = ({
  children,
  backgroundColor,
  hasOverflow = false,
  maxWidth = "none",
}: CircleWrapperProps) => (
  <CircleWrapperStyled
    $backgroundColor={backgroundColor}
    $hasOverflow={hasOverflow}
    $maxWidth={maxWidth}
  >
    <CircleContentWrapper>{children}</CircleContentWrapper>
  </CircleWrapperStyled>
);

export default CircleWrapper;
